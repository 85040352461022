import React from 'react';

import dynamic from 'next/dynamic';
import Head from 'next/head';

const LoginComponent = dynamic(() => import('../components')?.then((mod) => mod?.LoginComponent));

function LoginPage() {
  return (
    <>
      <Head>
        <title>{'Login | TR-OTM'}</title>
      </Head>
      <LoginComponent />
    </>
  );
}

export default LoginPage;
